import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`The wealthy and powerful run the show. Colonialism casts a long shadow. The art market is supremely opaque. `}<a parentName="p" {...{
          "href": "https://news.artnet.com/art-world/9-reasons-art-world-is-unfair-1726653"
        }}>{`Shall we go on?`}</a></p>
    </blockquote>
    <p>{`The art world is just one part of a larger system that disadvantages populations that have long been exploited on a global scale. It's so damaged, `}<a parentName="p" {...{
        "href": "https://www.vulture.com/2018/10/how-does-the-art-world-live-with-itself.html"
      }}>{`waist-deep in hypermarketing and excess`}</a>{` that it’s hard to look at art without being overcome by money, prices, auctions, art fairs, celebrities, well-known artists, and mega-collectors.`}</p>
    <p>{`Call bullshit. Support artists for art's sake. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      