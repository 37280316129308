import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`This is Art`}</h2>
    <p>{`Without art, we are but meatbags with car keys.`}</p>
    <p>{`Artists need your support.`}</p>
    <ProjectCard title="$1.-" link="https://www.behance.net/gallery/58937147/Freiheit" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Donate
    </ProjectCard>
    <ProjectCard title="$5.-" link="https://www.behance.net/gallery/52915793/Harry-Potter" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Donate
    </ProjectCard>
    <ProjectCard title="$any amount" link="https://www.behance.net/gallery/43907099/Tomb-Raider" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Eat the rich
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      